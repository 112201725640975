// ---------------------------------------------- modules import
import { FirebaseOptions } from "firebase/app";

// const firebaseConfig: FirebaseOptions = {
//   apiKey: "AIzaSyCz4AV-O4shelhTZHm2CVUt6siCmNRyA6A",
//   authDomain: "aktara-talenthive.firebaseapp.com",
//   projectId: "aktara-talenthive",
//   storageBucket: "aktara-talenthive.appspot.com",
//   messagingSenderId: "37713780794",
//   appId: "1:37713780794:web:7d1ad4598ef63d7e401503",
//   measurementId: "G-VVHLH0LXG4",
// };

const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyBX4bXqNCjXl-UYmPbHIrXjo6eRDuslBiY",
  authDomain: "ganesa-id-kab-garut.firebaseapp.com",
  projectId: "ganesa-id-kab-garut",
  storageBucket: "ganesa-id-kab-garut.appspot.com",
  messagingSenderId: "531878486628",
  appId: "1:531878486628:web:1a84e5cb9441c07e12b571",
  measurementId: "G-N8K22HXST7",
};

export default firebaseConfig;
